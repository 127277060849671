"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUsingTCDC = exports.FeatureFlag = void 0;
var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["Accept"] = "accept";
    FeatureFlag["Quote"] = "quote";
    FeatureFlag["AdvancedStyleEditor"] = "advancedStyleEditor";
    FeatureFlag["CustomWebfonts"] = "custom-webfonts";
    FeatureFlag["HostedWebfonts"] = "hosted-webfonts";
    FeatureFlag["PersistenceSafetyNet"] = "persistenceSafetyNet";
    FeatureFlag["UseRightToLeftBeta"] = "useRightToLeftBeta";
    FeatureFlag["Teams"] = "teams";
    FeatureFlag["AcceptCustomForms"] = "acceptCustomForms";
    FeatureFlag["Esign"] = "esign";
    FeatureFlag["IpFiltering"] = "ipFiltering";
    FeatureFlag["Domain"] = "domain";
    FeatureFlag["Subdomain"] = "subdomain";
    FeatureFlag["ViralAcceptFlow"] = "viralAcceptFlow";
    FeatureFlag["BluePrintApi"] = "bluePrintApi";
    FeatureFlag["QwilrApi"] = "qwilr-api";
    FeatureFlag["ZapierIntegration"] = "zapier integration";
    FeatureFlag["LegacyCustomDomain"] = "legacyCustomDomain";
    FeatureFlag["Emoji"] = "emoji";
    FeatureFlag["WhiteLabelAcceptFlow"] = "whiteLabelAcceptFlow";
    FeatureFlag["StylePermission"] = "stylePermission";
    FeatureFlag["NewEditorFancyColumns"] = "newEditor-fancyColumns";
    FeatureFlag["NotificationsWebhook"] = "notificationsWebhook";
    FeatureFlag["IdentityMapping"] = "identityMapping";
    FeatureFlag["PublicClonableProjects"] = "publicClonableProjects";
    FeatureFlag["AdvancedSecurity"] = "advanced security";
    FeatureFlag["MultipleCurrencies"] = "multipleCurrencies";
    FeatureFlag["Security"] = "security";
    FeatureFlag["LinkPreviewDefaultsOverride"] = "linkPreviewDefaultsOverride";
    FeatureFlag["WhiteLabelledEmails"] = "whiteLabelledEmails";
    FeatureFlag["DisableBeacon"] = "disableBeacon";
    FeatureFlag["AuditTrail"] = "audit trail";
    FeatureFlag["ActiveUsers"] = "activeUsers";
    FeatureFlag["BasicAnalytics"] = "basic analytics";
    FeatureFlag["TCDCPlayground"] = "TCDCPlayground";
    FeatureFlag["DocConverter"] = "docConverter";
    FeatureFlag["LiveChatIntegrations"] = "liveChatIntegrations";
    FeatureFlag["PublicApi"] = "publicApi";
    FeatureFlag["SideBySideResizing"] = "sideBySideResizing";
    FeatureFlag["DisableImgix"] = "disableImgix";
    FeatureFlag["OptOutPrintImageOptimisation"] = "optOutPrintImageOptimisation";
    FeatureFlag["PastingURLLinks"] = "pastingURLLinks";
    FeatureFlag["CanEditQuoteV1"] = "canEditQuoteV1";
    FeatureFlag["SendEmailsFromQwilrOnly"] = "sendEmailsFromQwilrOnly";
    FeatureFlag["PostAcceptExperience"] = "postAcceptExperience";
    FeatureFlag["OptOutAcceptExperienceSurvey"] = "optOutAcceptExperienceSurvey";
    FeatureFlag["PlatformTokens"] = "platformTokens";
    FeatureFlag["TemplatePageSettings"] = "templatePageSettings";
    FeatureFlag["OptionalLoginMethods"] = "optionalLoginMethods";
    FeatureFlag["HideFavicon"] = "hideFavicon";
    FeatureFlag["XeroIntegration"] = "xeroIntegration";
    FeatureFlag["AdvancedEngagementAlgorithms"] = "advancedEngagementAlgorithms";
    FeatureFlag["PipedriveV2"] = "pipedriveV2";
    FeatureFlag["OptOutPipedriveV2"] = "optOutPipedriveV2";
    FeatureFlag["ConsumerVirality"] = "consumerVirality";
    FeatureFlag["OptOutSpringboardEngagementMenu"] = "optOutSpringboardEngagementMenu";
    FeatureFlag["UserManagementService"] = "userManagementService";
    FeatureFlag["DisableClerkOnPublicPages"] = "disableClerkOnPublicPages";
    FeatureFlag["RefreshedNavigationMenu"] = "refreshedNavigationMenu";
    FeatureFlag["SpringboardAcceptedPages"] = "springboardAcceptedPages";
    FeatureFlag["SpringboardAcceptDiscovery"] = "springboardAcceptDiscovery";
    FeatureFlag["HubspotSyncEssentials"] = "hubspotSyncEssentials";
    FeatureFlag["CurrencyLocales"] = "currencyLocales";
    FeatureFlag["EditorRegistryWidgets"] = "editorRegistryWidgets";
    FeatureFlag["EditorDebugLogging"] = "dev:editorDebugLogging";
    FeatureFlag["DisableTmsFetching"] = "dev:disableTmsFetching";
    FeatureFlag["DisableEngagementAlgorithmFetching"] = "dev:disableEngagementAlgorithmFetching";
    FeatureFlag["DisableEditorSavingIndicator"] = "dev:disableEditorSavingIndicator";
    FeatureFlag["MakeQwilrYourOwn"] = "makeQwilrYourOwn";
    FeatureFlag["TailoredPages"] = "tailoredPages";
    FeatureFlag["PromotionBar"] = "promotionBar";
    FeatureFlag["ErgonomicsDragging"] = "ergonomicsDragging";
    FeatureFlag["AdvancedAnalyticsLimits"] = "advancedAnalyticsLimits";
    FeatureFlag["AllowBlockMigration"] = "allowBlockMigration";
    FeatureFlag["ReplaceSideBySideWithLayout"] = "replaceSideBySideWithLayout";
    FeatureFlag["UpdatePlanSeatsInStripe"] = "updatePlanSeatsInStripe";
    FeatureFlag["MultiGateway"] = "multiGateway";
    FeatureFlag["GrandfatherIPFiltering"] = "grandfatherIPFiltering";
    FeatureFlag["SalesforceLimitedTokens"] = "salesforceLimitedTokens";
    FeatureFlag["WidgetBlockStylesUI"] = "widgetBlockStylesUI";
    FeatureFlag["DocUploaderV1"] = "docUploaderV1";
    FeatureFlag["DocUploaderLayoutsAndStyles"] = "docUploaderLayoutsAndStyles";
    FeatureFlag["NewSalesforceIntegrationFlow"] = "newSalesforceIntegrationFlow";
    FeatureFlag["CopyPasteStyles"] = "copyPasteStyles";
    FeatureFlag["UnsavedStyleIndicators"] = "unsavedStyleIndicators";
    FeatureFlag["FontFamilyOverrides"] = "fontFamilyOverrides";
})(FeatureFlag || (exports.FeatureFlag = FeatureFlag = {}));
var isUsingTCDC = function (usingFeature) {
    return true;
};
exports.isUsingTCDC = isUsingTCDC;
exports.default = FeatureFlag;
