"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptFlowContextProvider = exports.AcceptFlowContextConsumer = exports.useAcceptFlowContext = void 0;
var react_1 = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var pages_common_1 = require("@qwilr/pages-common");
var AcceptFlowContext = react_1.default.createContext(undefined);
var useAcceptFlowContext = function () {
    var context = react_1.default.useContext(AcceptFlowContext);
    if (!context) {
        throw new Error("useAcceptFlowContext() must be used within a <AcceptFlowContextProvider> component");
    }
    return context;
};
exports.useAcceptFlowContext = useAcceptFlowContext;
exports.AcceptFlowContextConsumer = (0, mobx_react_1.observer)(function (_a) {
    var children = _a.children;
    var context = (0, react_1.useContext)(AcceptFlowContext);
    if (!context) {
        throw new Error("AcceptFlowContextConsumer must be used within a <AcceptFlowContextProvider> component");
    }
    return children(context);
});
var AcceptFlowContextProvider = function (_a) {
    var _b, _c;
    var children = _a.children, incomingSettings = _a.settings, autofillEnabled = _a.autofillEnabled, isPreviewAccept = _a.isPreviewAccept, payNowEnabled = _a.payNowEnabled, incompleteAccepterCount = _a.incompleteAccepterCount, postAcceptRedirectUrl = _a.postAcceptRedirectUrl, auditTrailPdfUrl = _a.auditTrailPdfUrl, optOutSpringboard = _a.optOutSpringboard, isPrintMode = _a.isPrintMode, isSimpleMode = _a.isSimpleMode, disableFormSubmitReason = _a.disableFormSubmitReason, telemetry = _a.telemetry, accepters = _a.accepters, paymentApiUrls = _a.paymentApiUrls, renderPaymentFormError = _a.renderPaymentFormError;
    var _d = __read((0, pages_common_1.usePubSubEvent)("qwilr:accept-flow-payment-added", {
        paymentAdded: (_b = incomingSettings.accepters.paymentAdded) !== null && _b !== void 0 ? _b : false,
        paymentAddedAt: (_c = incomingSettings.accepters.paymentAddedAt) !== null && _c !== void 0 ? _c : undefined,
    }), 1), _e = _d[0], paymentAdded = _e.paymentAdded, paymentAddedAt = _e.paymentAddedAt;
    var button = incomingSettings.button, _f = incomingSettings.accepters, acceptersPaymentAdded = _f.paymentAdded, acceptersPaymentAddedAt = _f.paymentAddedAt, acceptersData = __rest(_f, ["paymentAdded", "paymentAddedAt"]), postAcceptance = incomingSettings.postAcceptance, settings = __rest(incomingSettings, ["button", "accepters", "postAcceptance"]);
    var text = {
        button: (button === null || button === void 0 ? void 0 : button.customLabel) ? button.customLabel : "Accept",
        doneState: (button === null || button === void 0 ? void 0 : button.custom) ? button.customCompletionText : "",
    };
    return (react_1.default.createElement(AcceptFlowContext.Provider, { value: {
            settings: __assign(__assign({}, settings), { accepters: acceptersData }),
            isPrintMode: isPrintMode,
            isSimpleMode: isSimpleMode,
            text: text,
            autofillEnabled: autofillEnabled,
            isPreviewAccept: isPreviewAccept,
            payNowEnabled: payNowEnabled,
            paymentAddedAt: paymentAdded ? paymentAddedAt : undefined,
            incompleteAccepterCount: incompleteAccepterCount,
            postAcceptance: postAcceptance.enabled
                ? { actionLabel: postAcceptance.continueLabel, url: postAcceptRedirectUrl }
                : undefined,
            auditTrailPdfUrl: !!auditTrailPdfUrl ? auditTrailPdfUrl : undefined,
            optOutSpringboard: optOutSpringboard,
            telemetry: telemetry,
            disableFormSubmitReason: disableFormSubmitReason,
            accepters: accepters,
            paymentApiUrls: paymentApiUrls,
            renderPaymentFormError: renderPaymentFormError,
        } }, children));
};
exports.AcceptFlowContextProvider = AcceptFlowContextProvider;
